.editableGroups {
  .editMode {
    .unassignedList, .groupList {
      min-height: 100px;
      background-color: #F4D6CC;
      color: black;
      min-width: 300px;
      margin: 10px;
      padding: 5px;
    }

    .top {
      display: flex;
      justify-content: flex-end;
    }

    .groups {
      display: flex;
      flex-wrap: wrap;

      h5 {
        font-size: 16px;
      }
    }
  }

  .roGroups {
    display: flex;

    .group {
      min-width: 300px;
      h5 {
        font-size: 16px;
      }

    }
  }
}
