$sidebar-bg-color: #281100 !default;
$submenu-bg-color: #281100 !default;
$icon-bg-color: #281100 !default;
//@import '~react-pro-sidebar/dist/scss/styles.scss';
// https://coolors.co/32373b-4a5859-f4d6cc-f4b860-c83e4d

.superAdminApp {
  background: #32373B;
  height: 100vh;
  display: flex;
  width: 100%;
  color: rgba(255, 255, 255, 0.85);

  .mainArea {
    flex-grow: 1;
    margin-left: 50px;
    margin-top: 30px;
    overflow-y: auto;
  }

  .sidebarArea {
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: 8px 7px 15px -5px rgba(0,0,0,0.11);
    -webkit-box-shadow: 8px 7px 15px -5px rgba(0,0,0,0.11);
    -moz-box-shadow: 8px 7px 15px -5px rgba(0,0,0,0.11);

    .sidebar { border-right: 0}
    .sidebar-inner {
      background: #32373B;
      border-right: 0;
    }

    .menu {
      background: #32373B;
      color: rgba(255, 255, 255, 0.8);

      .menu-item {
        background: #32373B;

        &.active, &.active .menu-anchor {
          color: white;
          background: #32373B;
        }

        &.newUser {
          color: #F4B860;
        }
      }


      &.line-above {
        border-top: 1px solid rgba(255, 255,255, 0.3);
        margin-top: 10px;
        padding-top: 10px;
      }
    }
  }

  //.adminFrame {
  //  width: 800px;
  //  margin: 100px auto 0px auto;
  //  padding: 50px;
  //  border: 5px solid #e9ecef;
  //  border-radius: 10px;
  //
  //  hr {
  //    height: 3px;
  //    border-radius: 2px;
  //    color: #adb5bd;
  //    margin-bottom: 35px;
  //  }
  //
  //  .standardButtons {
  //    display: flex;
  //    justify-content: center;
  //    gap: 20px;
  //    margin-top: 20px;
  //  }
  //
  //  .timePicker {
  //    min-width: 100px;
  //  }
  //}
}
