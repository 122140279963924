.StreakBox {
  display: flex;
  align-items: center;
  background: white;
  background: #FFFFFF;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.25);
  border-radius: 38px;
  color: black;
  position: absolute;
  bottom: 30px;
  right: 30px;
  padding: 8px 21px 8px 11px;
  min-width: 279px;

  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    margin: 0px
  }

  h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 42px;
    margin: 0px
  }

  img {
    height: 79px;
    width: 65px;
    margin-right: 15px
  }

  [class~="texts"] {
    text-align: left;
  }
}

