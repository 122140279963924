.editUserPage {
  display: inline-block;

  h1 {
    margin-bottom: 20px;
  }
  .subPageArea {
    margin-top: 30px
  }

  table {
    margin-left: 20px;

    td, th {
      padding-bottom: 5px;
    }
  }
  button {
    margin-top: 15px;
  }

  .buttons {
    margin-top: 15px;
    justify-content: space-between;
    display: flex;
  }


}
