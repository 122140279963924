@font-face {
  font-family: 'Spartan';
  font-weight: 100;
  src: url('../../public/fonts/Spartan-Thin.ttf') format('truetype');
}
@font-face {
  font-family: 'Spartan';
  font-weight: 200;
  src: url('../../public/fonts/Spartan-ExtraLight.ttf') format('truetype');
}
@font-face {
  font-family: 'Spartan';
  font-weight: 300;
  src: url('../../public/fonts/Spartan-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'Spartan';
  font-weight: 400;
  src: url('../../public/fonts/Spartan-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Spartan';
  font-weight: 500;
  src: url('../../public/fonts/Spartan-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'Spartan';
  font-weight: 600;
  src: url('../../public/fonts/Spartan-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: 'Spartan';
  font-weight: 700;
  src: url('../../public/fonts/Spartan-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Spartan';
  font-weight: 800;
  src: url('../../public/fonts/Spartan-ExtraBold.ttf') format('truetype');
}
@font-face {
  font-family: 'Spartan';
  font-weight: 900;
  src: url('../../public/fonts/Spartan-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato';
  font-weight: 100;
  src: url('../../public/fonts/Lato-Thin.ttf') format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-weight: 200;
  src: url('../../public/fonts/Lato-Hairline.ttf') format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-weight: 300;
  src: url('../../public/fonts/Lato-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-weight: 400;
  src: url('../../public/fonts/Lato-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-weight: 500;
  src: url('../../public/fonts/Lato-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-weight: 600;
  src: url('../../public/fonts/Lato-Semibold.ttf') format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-weight: 700;
  src: url('../../public/fonts/Lato-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-weight: 900;
  src: url('../../public/fonts/Lato-Black.ttf') format('truetype');
}

body {
  overflow: hidden;
}

.App {
  height: 1080px;
  width: 1920px;
  background-image: url(../../public/background.svg);
  display: flex;
  flex-direction: column;
  font-family: Spartan;
  font-style: normal;
  color: white;
}

#topArea {
  height: 172px;
  margin-left: 56px;
}

#middleArea {
  margin-left: 56px;
  margin-right: 56px;
  margin-bottom: 30px;
  flex-grow: 1;
  display: flex;
  margin-right: 56px;

  .midCenter {
    flex-grow: 1;
    padding-right: 27px;
    padding-left: 27px;
  }

  .midRight {
    width: 581px;
    display: flex;
    gap: 27px;
    flex-direction: column;
  }

  .midLeft {
    width: 275px;
  }
}

#bottomArea {
  height: 180px;
  //background: linear-gradient(180deg, rgb(0 66 105 / 60%) 0%, rgb(0 39 63 / 60%) 100%);
}

h1 {
  font-weight: 700;
  font-size: 72px;
  margin: 0px;
  line-height: 80px;
}

h2 {
  font-weight: 600;
  font-size: 36px;
  line-height: 42px;
  margin: 0px;
}

.blueBox {
  background: linear-gradient(180deg, #004269 0%, #00273F 100%);
  color: white;
  z-index: 10;
  border-radius: 60px;
  filter: drop-shadow(0px 0px 40px rgba(0, 66, 105, 0.4));

  &.inactive:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 60px;
    background: rgba(0,0,0,0.3);
  }

  &.inactive {
    opacity: 0.6;
  }
  h2 {
    font-size: 36px;
    font-weight: 600;
  }

  h1 {
    margin-top: 18px;
    font-size: 72px;
    font-weight: 700;
    white-space: nowrap;
  }
}

.barArea {
  path, line {
    stroke-linecap: round;
    stroke-width: 11.3;
  }

  path.meterBackground, line.meterBackground {
    stroke: #3B49E3;
  }

  path.meter, line.meter {
    stroke: url(#gradient2);
    //stroke-dasharray: 70px, 198px;
    stroke-dashoffset: 1px;
    //stroke: #e7e7e8;
  }
}
.speedoMeter {
  width: 803px;
  position: absolute;
  top: -96px;
  left: 28px;

  svg {
    //margin: auto;
    //display: block;
  }

  path {
    stroke-linecap: round;
    stroke-width: 6.1;
  }

  path.meterBackground {
    stroke: #3B49E3;
  }

  path.meter {
    stroke: url(#gradient);
    //stroke-dasharray: 70px, 198px;
    stroke-dashoffset: 1px;
    //stroke: #e7e7e8;
  }
}


