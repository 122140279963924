.buttonGroupContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;

  button {
    background: #FFFFFF;
    opacity: 0.5;
    border-radius: 70px;
    padding: 2px 24px;
    height: 21px;
    border: 0px;
    font-size: 14px;
    color: #282828;
    line-height: 15px;

    &:hover {
      opacity: 1;
    }
    &.selected {
      opacity: 100%;
      background: #78E7A0;
    }
  }
}

.box {
  padding: 30px 32px;

  /* Block gradiant v2 */
  background: linear-gradient(180deg, #004269 0%, #00273F 100%);
  border-radius: 60px;
}

.clickable {
  cursor: pointer;
}
