.campaignsPage {
  h3 {
    margin-top: 20px;
  }

  .userCampaignCard {
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 30px;
    margin-right: 30px;
  }
}
