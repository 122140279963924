.TopBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-top: 34px;

  .logo {
    height: 116px;

    img {
      height: 100%;
    }

    .ErrorLogo {
      width: 100px;
      color: red;
    }

    cursor: pointer;
  }
  .inactiveHeaders {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-bottom: 56px;
    margin-top: -17px;

    h1 {
      font-weight: 700;
      font-size: 72px;
      margin: 0px;
    }
    h3 {
      font-style: normal;
      font-weight: 600;
      font-size: 30px;
      line-height: 34px;
      padding-left: 22px;
      color: rgba(255, 255, 255, 0.6);
    }
  }

  h1 {
    font-family: Spartan;
    font-style: normal;
    font-weight: bold;
    font-size: 72px;
    padding-top: 17px;
    flex-grow: 1;
    padding-left: 22px;
  }


  [class~="aliveDrop"] {
    width: 253px;
    height: 253px;
    margin-top: -69px;
    margin-right: -39px;
  }
}

