.StatsPage {
  flex-grow: 1;

  .displayControlsArea {
    padding-left: 10px;
    //border: solid 1px rgba(255, 255, 255, 0.5);
    border-radius: 7px;
    //box-shadow: 8px 7px 15px 1px rgba(0, 0, 0, 0.47);
    //-webkit-box-shadow: 8px 7px 15px 1px rgba(0, 0, 0, 0.47);
    //-moz-box-shadow: 8px 7px 15px 1px rgba(0, 0, 0, 0.47);
    //background-color: #4A5859;
    color: #F4D6CC;
    font-size: 16px;
    min-width: 350px;
    margin-bottom: 20px;
    margin-right: 10px;

    .topArea {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }
  }

  .box {
    .content {
      margin-top: 20px;
    }

    .simple {
      display: flex;
      flex-direction: column;
      align-items: center;

      .statBoxes {
        justify-content: center;

        .simpleStatsArea {
          display: flex;
          flex-direction: row;
          justify-content: center;
          gap: 50px;

          .simpleStatBox {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 9px;

            .header {
              //font-family: 'Lato';
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
            }

            .mainText {
              font-family: 'Spartan';
              font-style: normal;
              font-weight: 600;
              font-size: 20px;
              line-height: 22px;
            }
          }

          .simpleMealStatBox {
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 12px 14px;
            gap: 12px;
            background: #FFFFFF;
            /* Drop Shadow */
            box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.25);
            border-radius: 21px;
            color: #282828;

            .header {
              font-family: 'Spartan';
              font-style: normal;
              font-weight: 600;
              font-size: 20px;
              line-height: 22px;

            }

            .value, .mainText {
              font-weight: 400;
              font-size: 12px;
            }
          }
        }
      }

      .graphArea {
        background: #F4D6CC;
        width: 100%;
        margin-top: 20px;

        display: flex;
        position: relative;
        flex-direction: column;
        align-items: center;
        padding: 16px;

        .graph {
          margin-top: 15px;
          width: 100%;
          height: 400px;
          position: relative;
        }
      }
    }
  }

  h4 {
    padding-top: 20px;
  }

  .wasteTable {
    tr {
      text-align: center;

      &.highlight {
        color: #F4B860;
      }

      &:nth-child(even) {
        background-color: rgba(255, 255, 255, 0.05);
      }

      &:first-child th:not(:first-child) {
        border-left: 1px solid rgba(255, 255, 255, 0.3)
      }


      th:nth-child(1), td:nth-child(1) {
        text-align: left;
        padding-right: 13px;
      }

      td:nth-child(even), th:nth-child(even) {
        border-left: 1px solid rgba(255, 255, 255, 0.3)
      }

      td {
        &.date {
          white-space: nowrap;
        }


      }

    }
  }
}
