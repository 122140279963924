.TotalSavedBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 30px 32px;

  [class~="texts"] {
    text-align: left;
  }

  [class~="red"] {
    color: #FF6636;
  }
}

