.TickCard {
  margin-top: 5px;
  border-radius: 14px;
  padding: 10px;
  background: rgba(248, 251, 251, 0.81);
  display: flex;
  align-items: center;
  gap: 10px;

  .textArea {
    flex-grow: 1;
  }

  img {
    height: 28px;
  }

  button {
    background: white;
    color: black;
  }

  label {
    font-size: small;
    padding: 0px;
  }
}

.DeleteItem {
  color: red;
  width: 30px;
  height: 30px;
  cursor: pointer;
}
