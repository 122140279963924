.wasteTable {
  flex-grow: 1;

  td, th {
    padding-left: 20px;

    &.offset {
      font-weight: normal;
    }
  }

  .nonZero {
    color: white;
    font-weight: bold;
  }

  .weight {
    padding-left: 20px;
    display: flex;
    //justify-content: space-between;

    span {
      flex-grow: 1;
    }
  }

  .deleteButton {
    color: #dd3e00;
  }

  .deleteSymbol {
    color: red;
  }

}

.select {
  color: black;
}
.addArea {
  border: 1px rgba(255,255,255,0.35) solid;
  border-radius: 6px;
  padding: 22px;
  margin: 36px;
  width: auto;
  display: inline-block;
  min-width: 500px;

  td:first-child {
    text-align: left;
    padding: 10px 23px 10px 10px;
  }

  .saveNewArea {
    display: flex;
    justify-content: center;
    margin-top: 26px;
  }
}
