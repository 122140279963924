.userCampaignCard {
  padding: 17px 32px 30px;
  background: linear-gradient(180deg, #004269 0%, #00273F 100%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 35px;

  .success {
    color: #01d28e !important;
  }

  .fail {
    color: #78E7A0 !important;
  }

  .red {
    color: #78E7A0;
    fill: #78E7A0;
  }

  .white {
    color: white;
  }
  .green {
    color: #01d28e;
    fill: #01d28e;
  }

  .header {
    h6 {
      font-family: 'Lato';
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #FFFFFF;
      opacity: 0.75;
    }

    h5 {
      font-family: 'Spartan';
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
    }
  }

  &.success {
    border-color: #01d28e;
  }

  &.failed {
    border-color: #78E7A0;
  }

  &.completed {
    opacity: 50%;
  }



  .results {
    .soloMealGoals {
      font-size: 12px;
      .status {
        //text-align: center;

        .waste {
          font-family: 'Spartan';
          font-style: normal;
          font-weight: 600;
          font-size: 30px;
          line-height: 34px;
          margin-right: 25px;
        }
      }
    }
  }
}
