.AllUsersPage {
  .header {
    display: flex;
    margin-right: 30px;

    h1 {
      margin-right: 50px;
    }
  }
  .tableHeader {
    margin-top: 30px;
  }

  table {
    width: 80%;
  }

  tr {
    height: 60px;

    &.blacklist {
      opacity: 60%;
    }

    &.noContact {
      color: #ff9b9b;
    }
  }

  tr:nth-child(odd) {
    background-color: rgba(255, 255, 255, 0.05);
  }

  tr:first-child {
    border-bottom: 1px solid white;
  }

  th {
    text-align: center;
  }

  td {
    padding-left: 15px;
  }

  .align-center {
    text-align: center;
  }

  .align-right {
    text-align: right;
  }
  .statusDot {
    zoom: 4;
    margin-top: -15px;
    margin-bottom: -15px;

    &.red {
      color: #f61e1e;
      fill: #f61e1e;
    }

    &.green {
      color: #01d28e;
      fill: #01d28e;
    }
  }
}


