.TickEditorPage {
  .box {
    margin-top: 10px;
    margin-bottom: 20px;

    i {
      font-size:14px;
    }

    .activationArea {
      display: flex;
      align-items: center;
      margin-top: 20px;

      .form-switch {
        margin-left: 40px;
        zoom: 1.4;
      }

    }
  }
}
