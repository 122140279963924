.MealCard {
  margin-top: 20px;
  //border: 1px gray solid;
  //border-radius: 14px;
  padding: 25px;
  padding-top: 15px;
  //background: #4A5859;
  color: white;

  .Header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  h3 {
    margin-bottom: 24px;
    margin-top: 10px;
  }

  input {
    min-height: 38px;
  }

  .readOnly {
    h3 {
      margin-bottom: 10px;
      margin-top: 5px;
    }

    label {
      font-weight: 500;
      margin-right: 10px;
    }
  }
}
