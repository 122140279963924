.BlacklistManager {
  .datesList {
    display: flex;
    justify-content: center;
    margin-top: 15px;

    tr.weekend {
      td:first-child {
        color: #ffc107;
      }
    }

    th:not(:first-child) {
      padding-left: 10px;
      padding-right: 10px;
    }

    td {
      &.buttons {
        display: flex;
        gap: 5px;
      }
      vertical-align: middle;
    }

    td:first-child {
      padding-bottom: 10px;
    }

    td:not(:first-child) {
      text-align: center;
      input {
        zoom: 1.5;
        accent-color: green;
      }
    }

    button {
      opacity: 60%;
    }
  }
}
