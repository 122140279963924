.LeftBox {
  display: flex;
  flex-direction: column;
  margin: 0px 30px;
  align-items: center;
  height: 100%;

  [class~="header"] {
    font-weight: 600;
    font-size: 36px;
    margin-top:30px;
    margin-bottom: 35px;
  }

  [class~="barArea"] {
    flex-grow: 1;
    margin-bottom: 30px;
    width: 100%;

    [class~="bar"] {
      position: absolute;
      height: 600px;
      left: 6px;
      top: 90px;
    }

    [class~="dailyGoalBox"] {
      z-index: 100;
      position: absolute;
      left: 21px;
      display: flex;

      [class~="circle"] {
        background: white;
        border-radius: 50%;
        width: 72px;
        height: 72px;

        img {
          margin-left: 8px;
          margin-top: 7px;
        }
      }

      [class~="texts"] {
        margin-left: 8px;
        margin-top: 10px;

        span {
          font-weight: normal;
          font-size: 18px;
        }

        h4 {
          margin: 0px;
          font-weight: 600;
          font-size: 24px;
          margin-top: 5px;
        }
      }
    }

    [class~="currentBox"] {
      z-index: 100;
      position: absolute;
      left: 21px;
      display: flex;
      background: white;
      height: 72px;
      border-radius: 36px;
      width: 233px;
      color: black;
      box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.25);

      img {
        margin-left: 8px;
        margin-top: 8px;
        height: 56px;
      }

      [class~="texts"] {
        margin-left: 16px;
        margin-top: 10px;

        span {
          font-weight: normal;
          font-size: 18px;
        }

        h4 {
          margin: 0px;
          font-weight: 600;
          font-size: 24px;
          margin-top: 5px;
        }
      }
    }
  }
}