.SoloMealGoalsCampaign {
  padding-top: 20px;
  padding-left: 41px;

  .green {
    color: var(--vejstrup-green, #78E7A0);
  }

  .red {
    color: var(--red, #FF6636);
  }

  .tinyTopText {
    color: #FFF;
    font-size: 18px;
    font-family: Lato;
    margin-bottom: 18px;
  }

  .remainingText {
    font-size: 36px;
    font-family: Spartan;
    font-weight: 600;
    line-height: 42px;
  }

  .center {
    height: 260px;
    text-align: right;

    .monthLimit {
      position: absolute;
      right: 31px;
      top: 254px;
    }

    .barArea {
      //position: relative;
      .bar {
        position: absolute;
        left: 38px;
        top: 171px;
        height: 83px;
        width: 518px;

        .backgroundBar {
          position: absolute;
          background-color:  #3B49E3;
          height: 100%;
          width: 100%;
          border-radius: 41px;

        }

        .meter {
          position: absolute;
          height: 100%;
          border-radius: 41px;
          box-shadow: 0px 0px 20px rgba(120, 231, 160, 0.25), 0px 0px 30px rgba(120, 231, 160, 0.3);

          &.failed {
            box-shadow: none;
          }
        }

        .failedSmiley {
          width: 56px;
          height: 56px;
          position: absolute;
          z-index: 10;
          left: 10px;
          top: 13px;
        }

        .whiteBox {
          position: absolute;
          top: 5px;
          min-width: 101px;
          height: 73px;
          padding: 8px 16px 8px 20px;
          border-radius: 53px;
          background: #FFF;
          box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.25);
          font-size: 36px;
          font-family: Spartan;
          font-weight: 600;
          color: #282828;
          z-index: 3;
          line-height: 64px;
          white-space: nowrap;
        }

        .warningBox {
          display: flex;
          height: 38px;
          padding: 8px 10px 8px 4px;
          align-items: center;
          gap: 10px;
          flex-shrink: 0;
          position: absolute;
          z-index: 4;
          border-radius: 38px;
          background: var(--red, #FF6636);
          box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.25);
          top: -50px;

          span  {
            color: #FFF;
            text-align: right;
            font-size: 18px;
            font-family: Lato;
            line-height: 24px;
          }
        }

        .warningLine {
          position: absolute;
          z-index: 4;
          //left: 150px;
          top: -8px;
          height: 100px;
          border-left: 1px dashed var(--red, #FF6636);;
        }
      }
    }

    .header {
      font-size: 18px;
      font-family: Lato;
      line-height: 220%;
    }

    .weight {
      font-size: 24px;
      font-family: Spartan;
      font-weight: 600;
      line-height: 31.9px;
    }
  }

  .bottom {
    display: grid;
    justify-content: space-between;
    margin-right: 41px;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;

    .dayEntry {
      display: flex;
      flex-direction: column;

      .weight {
        text-align: center;
        font-size: 22px;
        font-family: Spartan;
        font-weight: 600;
        line-height: 31.9px;
      }

      .dayName {
        text-align: center;
        font-size: 22px;
        font-family: Lato;
      }
    }
  }
}
