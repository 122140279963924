.adminApp {
  height: 100vh;
  display: flex;
  width: 100%;
  background: #32373b;
  color: white;
  background-image: url(../../public/background.svg);
  background-size: cover;

  .mainArea {
    flex-grow: 1;
    overflow: auto;
  }

  .sidebarArea {
    background: linear-gradient(180deg, #004269 0%, #00273F 100%);

    .sidebarHeader {
      text-align: center;
      margin-bottom: 20px;

      img {
        width: 100px;
        margin-bottom: 14px;
      }

    }

    .sidebar {
      border-right: 0px;

      .sidebar-inner, .sub-menu-content {
        background-color: inherit;
      }

      .menu {
        //background:  linear-gradient(180deg, #004269 0%, #00273F 100%);;
        color: rgba(255, 255, 255, 0.8);

        .menu-item {
          //background: linear-gradient(180deg, #004269 0%, #00273F 100%);

          &.active, &.active .menu-anchor {
            color: white;
            background-color: inherit;
            font-weight: bold;
          }
        }

        &.borderTop {
          border-top: 1px solid rgba(255, 255, 255, 0.3);
          margin-top: 10px;
          padding-top: 10px;
        }
      }
    }
  }


  .adminFrame {
    margin: 60px;
    height: 100%;

    h1.pageHeader {
      margin-bottom: 50px;
      font-family: "Spartan";
    }


    hr {
      height: 3px;
      border-radius: 2px;
      color: #adb5bd;
      margin-bottom: 35px;
    }

    .standardButtons {
      display: flex;
      justify-content: center;
      gap: 20px;
      margin-top: 20px;
    }

    .timePicker {
      min-width: 100px;
    }
  }
}
