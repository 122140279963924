.traceTable {
  flex-grow: 1;

  td, th {
    padding-left: 20px;

    &.offset {
      font-weight: normal;
    }
  }

  .spin{
    margin-right: 7px;
    top: 2px;
    animation: spin 1s infinite linear;
  }

  @keyframes spin {
    from {
      transform: scale(1) rotate(0deg);
    }
    to {
      transform: scale(1) rotate(360deg);
    }
  }

  .nonZero {
    color: white;
    font-weight: bold;
  }
  .weight {
    padding-left: 20px;
  }
}
