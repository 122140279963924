.BottomBar {
  padding-bottom: 56px;

  .ticker {
    overflow: hidden;

    .tickerItem {
      border-radius: 90px;
      background: linear-gradient(180deg, #64C1ED 0%, #4AAAD7 100%);
      box-shadow: 0px 4px 32px 0px rgba(120, 231, 160, 0.20);
      height: 124px;
      padding: 16px 16px 20px 16px;
      gap: 15px;
      margin-left: 32px;
      display: flex;
      align-items: center;

      span {
        color: var(--Mine-Shaft, #282828);
        font-family: Spartan;
        font-style: normal;
        font-weight: 600;
        font-size: 25px;
      }

      img {
        height: 92px;
        border-radius: 50%;
        border: 3px black solid;
      }
    }
  }
}
