.GroupContestCampaign {
  padding: 15px 32px 0px 32px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      margin-top: 15px;
      font-family: Spartan;
      font-size: 36px;
      font-weight: 600;
    }

    span {
      align-self: flex-start;
      font-family: Lato;
      font-size: 18px;
      font-weight: 400;
      line-height: 24px
    }
  }

  .positionsList {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .entry {
      display: flex;
      height: 73px;
      padding: 20px 16px 16px;
      align-items: center;
      gap: 10px;
      flex-shrink: 0;
      border-radius: 38px;
      background: #FFF;
      color: black;

      &.winner {
        background: var(--Green, #78E7A0);
        box-shadow: 0px 0px 32px 0px rgba(120, 231, 160, 0.60), 0px 0px 12px 0px rgba(120, 231, 160, 0.40);
      }

      .column0 {
        width: 60px;

        img {
          height: 50px
        }

        span {
          color: var(--Dark-text, #282828);
          text-align: center;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: Spartan;
          font-size: 24px;
          font-weight: 600;
          line-height: 42px; /* 175% */
          display: inline-block;
          width: 40px;
        }
      }

      .schoolName {
        font-family: Spartan;
        font-size: 24px;
        font-weight: 600;
        line-height: 31.9px;
        flex-grow: 1;
      }

      .weight {
        color: var(--Dark-text, #282828);
        text-align: right;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Spartan;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 42px; /* 175% */
      }
    }
  }

  .dots {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin: 14px;
  }
}
