.campaignsPage {
  .openedCampaignArea {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100vh;

    .cardContainer {
      position: relative;
      margin-top: 30px;
      max-width: 1000px;
      margin-left: auto;
      margin-right: auto;
    }

    .backdrop {
      width: 100vh;
      height: 100vh;
      position: fixed;
      backdrop-filter: blur(3px);
    }

    .cardContainer {
      z-index: 20;
    }
  }
  .campaignListingArea {
    .headerBar {
      display: flex;
      justify-content: space-between;
      margin-right: 20px;
      align-items: center;
    }

    h2 {
      margin-top: 20px;
    }

    h3 {
      margin-top: 15px;
      font-size: 20px;
    }

    .campaignCards {
      display: flex;
      flex-wrap: wrap;
    }
  }
}
