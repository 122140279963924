.logsPage {
  h3 {
    margin-top: 20px;
  }

  .logEntries {
    table {
      min-height: 300px;
      width: 100%;
    }

    tr:nth-child(even) {
      background-color: rgba(255, 255, 255, 0.05);
    }

    tr:first-child {
      border-bottom: 1px solid white;
    }

    tr td {
      white-space: nowrap;

      &:first-child, &:nth-child(2) {
        padding-right: 20px;
      }

      &:last-child {
        width: 100%;
        white-space: inherit;
      }
    }

    tr.yellow {
      color: #ffc107
    }

    tr.red {
      color: #f61e1e
    }

  }
}
