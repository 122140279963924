.MainBox {
  height: 100%;

  [class~="outerBox"] {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    border-radius: 60px;
    position: relative;
    overflow: hidden;

    [class~="innerBox"] {
      flex-grow: 1;
      padding-top: 40px;

      [class~="centerText"] {
        display: flex;
        flex-direction: column;
        align-items: center;

        [class~="savedInfoBox"] {
          background: #78E7A0;
          box-shadow: 0px 0px 10px rgba(120, 231, 160, 0.25), 0px 0px 30px rgba(120, 231, 160, 0.3);
          border-radius: 60px;
          color: black;
          font-size: 24px;
          line-height: 32px;
          font-weight: 600;
          margin-top: 170px;
          padding: 10px 15px 6px 15px;

          &[class~="red"] {
            background: #FF6636;
            box-shadow: 0px 0px 10px rgba(255, 102, 54, 0.25), 0px 0px 30px rgba(255, 102, 54, 0.3);
          }
        }

        [class~="mainText"] {
          margin-top: 66px;

          [class~="weight"] {
            font-weight: bold;
            font-size: 130px;
            line-height: 123px;
          }

          [class~="unit"] {
            font-weight: 600;
            font-size: 56px;
            line-height: 63px;
          }
        }

        [class~="subText"] {
          font-weight: 600;
          font-size: 36px;
          line-height: 42px;
        }
      }

      [class~="tick"] {
        font-weight: 600;
        font-size: 24px;
      }

      [class~="tick0"] {
        position: absolute;
        left: 249px;
        top: 586px;
      }

      [class~="tick25"] {
        position: absolute;
        left: 181px;
        top: 292px;
      }

      [class~="tick50"] {
        position: absolute;
        left: 438px;
        top: 109px;
      }

      [class~="tick75"] {
        position: absolute;
        right: 175px;
        top: 292px;
        color: #FF6636
      }

      [class~="goalBox"] {
        display: flex;
        flex-direction: row;
        align-items: center;
        background: #FFFFFF;
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.25);
        border-radius: 84px;
        color: black;
        position: absolute;
        right: 36px;
        top: 116px;
        padding: 8px 21px 8px 11px;

        [class~="goalText"] {
          margin-left: 11px;
          display: flex;
          flex-direction: column;

          span {
            margin-bottom: 5px;
            margin-top: 7px;
          }
          h3 {
            margin: 0px;
            font-weight: 600;
            font-size: 24px;
          }
        }
      }
    }
  }
}