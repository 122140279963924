.userPage {
  .subPageArea {
    margin-top: 30px
  }
  button {
    margin-left: 20px;
  }

  .pointer {
    cursor: pointer;
  }
}


