.statsControls {
  label.lbl, label.periodHeader {
    margin-right: 15px;
    font-weight: 500;
  }

  .line {
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .periodArea {
    display: flex;

    vertical-align: middle;



    .pickers {
      display: inline-block;
      margin-left: 20px;

      &.disabled {
        .fromLbl, .toLbl {
          opacity: 50%;
        }
      }

      .fromLbl, .toLbl {
        margin-right: 5px;
      }

      .toLbl {
        margin-left: 10px;
      }
      .datePicker, .datePicker input {
        width: 110px;
      }
    }

  }


}
