.logoHandler {
  padding: 10px;
  border: 1px solid rgba(255,255,255,0.4);
  border-radius: 6px;
  text-align: center;

  img {
    width: 90px;
  }
  .uploadArea {
    display: flex;
    align-items: baseline;
    justify-content: space-around;
  }
}
