.campaignCard {
  padding: 10px;
  border: solid 1px rgba(255, 255, 255, 0.5);
  border-radius: 7px;
  box-shadow: 8px 7px 15px 1px rgba(0,0,0,0.47);
  -webkit-box-shadow: 8px 7px 15px 1px rgba(0,0,0,0.47);
  -moz-box-shadow: 8px 7px 15px 1px rgba(0,0,0,0.47);
  background-color: #4A5859;
  color: #F4D6CC;
  font-size: 16px;
  min-width: 350px;
  margin-bottom: 10px;
  margin-right: 10px;

  .header {
    display: flex;
    justify-content: space-between;

    h4 {
      margin-bottom: 0px;
      font-size: 18px;
    }
  }

  .type {
    font-weight: 500;
    color: #F4B860;
    display: flex;
  }

  .invalid {
    color: #C83E4D;
  }

  .valid {
    color: green;
  }

  .errorsArea {
    color: #C83E4D;

  }

  &.readOnly {
    .mainArea {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      .participants {
        p {
          margin-bottom: 0;

          &.noContestants {
            margin-left: 20px;
          }
        }
      }
    }
  }

  &.editAllowed {
    .mainArea {
      .buttons {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
