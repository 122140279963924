.DatesPage {
  flex-grow: 1;
}

.frame {
  width: 800px;
}

.datesArea {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 29px;
  margin-top: 24px;
}


.periodCalendars {
  margin-top: 24px;
  display: flex;
  justify-content: center;
  gap: 44px;
}

.labelInput {
  label {
    margin-right: 8px;
  }
}
